import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PredioApple from '../../images/predioApple.png'
import AppleBeach1Andar from '../../images/AppleBeach1Andar.png'
import AppleBeach2Andar from '../../images/AppleBeach2Andar.png'
import AppleBeachDuplex1 from '../../images/AppleBeachDuplex1.png'
import AppleBeachDuplex2 from '../../images/AppleBeachDuplex2.png'
import { Carousel } from "react-responsive-carousel";
import FotoBarraVelha from '../../images/FotoBarraVelha.jpg'
import Progress from '../../components/progress'
import LogoAppleSemFundo from '../../images/LogoAppleSemFundo.png';
import ImageModal from "../../components/imageModal"
import AppleBeachSala from "../../images/AppleBeachSala.png"
import AppleBeachBannerInterior from "../../images/AppleBeachBannerInterior.png"

export default function AppleBeach() {
    const [img, setImg] = useState('')
    const [plant, setPlant] = useState('0')
    const [imageVisible, setImageVisible] = useState(false)
    function handleChange(key) {
        setPlant(key)
    }
    function handleClick(key) {
        if (key === 0) {
            setImg(AppleBeach1Andar)

        }
        else if (key === 1) {
            setImg(AppleBeach2Andar)
        }
        else if (key === 2) {
            setImg(AppleBeachDuplex1)
        }
        else if (key === 3) {
            setImg(AppleBeachDuplex2)
        }
        setImageVisible(true)
    }
    return (
        <Layout image={<img className='lg:w-1/6 w-1/2 m-5 self-center' src={LogoAppleSemFundo} />}>
            <SEO
                keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
                title="Apple Beach"
            />
            <ImageModal {...{ setImageVisible, imageVisible, img }} />
            <h2 className='my-16 italic text-center'>Quer realizar seu sonho de um imóvel na praia?</h2>
            <h3 className='text-center'>A Bivver Empreendimentos Imobiliários apresenta o Apple Beach!</h3>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-12 my-12 self-center'>
                <img className='lg:w-1/2 lg:ml-32' src={PredioApple} />
                <p className='text-md'>O empreendimento está localizado na praia de Itajuba, cidade de Barra velha. A Região
                conta com supermercado atacadista, padarias, lojas e peixaria. A rua Biguaçu é calçada
e tem uma pracinha e academia ao ar livre .<br />
                    <br />
                    Além de estar bem localizado, o empreendimento tem um ótimo acabamento,
arquitetura moderna e a privacidade de ser um edifício com poucos apartamentos.<br />
                    <br />
                    São quatro apartamentos de 1 suíte mais 1 dormitório e dois apartamentos duplex de
2 suítes e 1 dormitório.<br />
                    <br />
              Realize seu sonho!
              </p>
            </div>
           
            <h2 className='my-10 text-center'>Plantas</h2>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-3'>
                <Carousel
                    infiniteLoop
                    selectedItem={plant}
                    showStatus={false}
                    stopOnHover
                    onClickItem={(key) => handleClick(key)}
                    onChange={(key) => handleChange(key)}
                    className='plan-carousel'
                >
                    <img
                        className="md:text-justify mr-auto"
                        src={AppleBeach1Andar}
                    />
                    <img
                        className="md:text-justify mr-auto"
                        src={AppleBeach2Andar}
                    />
                    <img
                        className="md:text-justify mr-auto"
                        src={AppleBeachDuplex1}
                    />
                    <img
                        className="md:text-justify mr-auto"
                        src={AppleBeachDuplex2}
                    />
                </Carousel>
                {plant === 0 ?
                    <div className='ml-10'>
                        <h3>Apartamento 101 - 60,14m²</h3>
                        <div className='mt-8'>
                            <div className='my-2'>1 Suíte e 1 dormitório</div>
                            <div className='my-2'>Sala de jantar</div>
                            <div className='my-2'>Sala de estar</div>
                            <div className='my-2'>Cozinha</div>
                            <div className='my-2'>Área de serviço</div>
                            <div className='my-2'>Banheiro social</div>
                            <div className='my-2'>Churrasqueira integrada</div>
                            <div className='my-2'>Área externa privativa</div>
                            <div className='my-2'>1 vaga de garagem</div>
                            <div className='my-2'>Piso em pocelanato</div>
                            <div className='my-2'>Detalhes em Gesso</div>
                        </div>
                    </div>
                    :
                    plant === 1 ?
                        <div className='ml-10'>
                            <h3>Apartamento 201 - 63,50m²</h3>
                            <div className='mt-8'>
                                <div className='my-2'>1 Suíte e 1 dormitório</div>
                                <div className='my-2'>Sala de jantar</div>
                                <div className='my-2'>Sala de estar</div>
                                <div className='my-2'>Cozinha</div>
                                <div className='my-2'>Área de serviço</div>
                                <div className='my-2'>Banheiro social</div>
                                <div className='my-2'>Churrasqueira integrada</div>
                                <div className='my-2'>Sacada</div>
                                <div className='my-2'>1 vaga de garagem</div>
                                <div className='my-2'>Piso em pocelanato</div>
                                <div className='my-2'>Detalhes em Gesso</div>
                            </div>
                        </div>
                        :
                        <div className='ml-10'>
                            <h3>Duplex - 108,10m²</h3>
                            <div className='mt-8'>
                                <div className='my-2'>3 Suítes</div>
                                <div className='my-2'>1 Dormitório</div>
                                <div className='my-2'>Sala de jantar</div>
                                <div className='my-2'>Sala de estar</div>
                                <div className='my-2'>Cozinha</div>
                                <div className='my-2'>Área de serviço</div>
                                <div className='my-2'>Banheiro social</div>
                                <div className='my-2'>1 Churrasqueira por andar</div>
                                <div className='my-2'>Sacada</div>
                                <div className='my-2'>Área externa privativa</div>
                                <div className='my-2'>2 vagas de garagem</div>
                                <div className='my-2'>Piso em pocelanato</div>
                                <div className='my-2'>Detalhes em Gesso</div>
                            </div>
                        </div>
                }
            </div>
            <br />
            <br />
            <center><h2>Acompanhamento da obra</h2></center>
            <center><h5>Data de inicio: 09/2019 | Data de término : xx/xxxx</h5></center>
            <br />
            <div className='grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center'>
                <h4 className='bivverBlack font-semibold'>Terraplanagem</h4>
                <Progress percentage='100' />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center'>
                <h4 className='bivverBlack font-semibold'>Fundação</h4>
                <Progress percentage='100' />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center'>
                <h4 className='bivverBlack font-semibold'>Estrutura</h4>
                <Progress percentage='81' />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center'>
                <h4 className='bivverBlack font-semibold'>Alvenaria</h4>
                <Progress percentage='79' />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center'>
                <h4 className='bivverBlack font-semibold'>Pinturas</h4>
                <Progress percentage='0' />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center'>
                <h4 className='bivverBlack font-semibold'>Revestimento em Gesso</h4>
                <Progress percentage='0' />
            </div>
            <div>
                <h2 className="font-bold text-center mt-16 mb-10">Localização</h2>
                <iframe
                    className='lg:w-1/2 w-full lg:mx-64 border-solid border-2 border-blue-500'
                    title="SoftinLocationMap"
                    height="360"
                    frameBorder="0"
                    src="https://maps.google.com/maps?q=rua%20Bigua%C3%A7u%2C%20535.%20Praia%20de%20Itajuba%2C%20Barra%20Velha&t=&z=15&ie=UTF8&iwloc=&output=embed"
                    allowFullScreen=""
                />
            </div>
            <h2 className='text-center mt-16'>Galeria de imagens</h2>
            <Carousel
                infiniteLoop
                showThumbs={false}
                showStatus={false}
                stopOnHover
                className='plan-carousel mb-20 mt-8'
            >
                <img src={AppleBeachSala} />
                <img src={AppleBeachBannerInterior} />
            </Carousel>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-12 my-16 self-center'>
                <div>
                    <h2 className='mb-8'>Por que morar em Barra Velha?</h2>
                    <p className='text-sm'>Barra Velha, localizada em Santa Catarina, é uma praia bastante visitada e conhecida devido seu fácil acesso e a localização. Detentora de praias lindas, bares e restaurantes com uma excelente gastronomia, oferece também ótimas opções de passeios, como a prática de esportes aquáticos e pesca esportiva.
                    <br />
                        <br />
                    O Empreendimento Apple Beach está localizado na Praia de Itajuba, uma das mais belas praias de Santa Catarina!
                    </p>
                </div>
                <img src={FotoBarraVelha} />
            </div>
        </Layout>
    )
}